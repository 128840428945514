import { API } from '@core/config/api';
import { IToken } from '@core/models/interfaces/tokenInterfaces';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';
import { OrderPromise } from '@components/LandingComponents/Forms/handlers/handlers.types';
import { IInvestmentForm } from '@sections/Investment/Investment.type';

export interface IOrders
	extends Omit<IInvestmentForm, 'isTerms' | 'isNotUsCitizen' | 'isAgreedRisk'> {
	address: string;
	tokenData: IToken;
}

export const Orders = {
	async post({ email, amount, sell_round, network, token, address }: IOrders) {
		const bodyData = {
			email,
			network,
			token,
			amount,
			sell_round,
			address
		};

		try {
			const res = await fetch(`${API.orders}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify(bodyData)
			});

			if (!res.ok) {
				const error = await res.json();
				return logErrors(error);
			}

			const { id, receiver_address }: OrderPromise = await res.json();
			return { id, receiver_address };
		} catch (error) {
			return logErrors(error);
		}
	}
};
