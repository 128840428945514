import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { ReactComponent as LogoImgIcon } from '@assets/icons/logo/logo-icon.svg';
import { ReactComponent as LogoTextIcon } from '@assets/icons/logo/logo-text.svg';
import { ReactComponent as DiscordIcon } from '@assets/icons/social/Discord.svg';
import { ReactComponent as LinkedIn } from '@assets/icons/social/LinkedIn.svg';
import { ReactComponent as Medium } from '@assets/icons/social/Medium.svg';
import { ReactComponent as RedditIcon } from '@assets/icons/social/Reddit.svg';
import { ReactComponent as TelegramIcon } from '@assets/icons/social/Telegram.svg';
import { ReactComponent as TwitterIcon } from '@assets/icons/social/Twitter.svg';
import { ReactComponent as YoutubeIcon } from '@assets/icons/social/Youtube.svg';

import styles from './Footer.module.scss';

export const Footer = () => {
	const exploreListData = [
		{ url: 'https://zen.land ', name: 'Home' },
		{ url: 'https://app.zen.land/', name: 'Zenland App' },
		{ url: 'https://docs.zen.land/', name: 'Documentation' }
	];

	const engageListData = [
		{ url: 'https://t.me/zenlandapp', icon: TelegramIcon },
		{ url: 'https://www.youtube.com/channel/UCi6Jrj8K8wU-QQH1E_ZAHVA', icon: YoutubeIcon },
		{ url: 'https://twitter.com/zenland_app', icon: TwitterIcon },
		{ url: 'https://www.reddit.com/r/ZenLand/', icon: RedditIcon },
		{ url: 'https://discord.gg/gXdd8bd6Ez', icon: DiscordIcon },
		{ url: 'https://www.linkedin.com/company/zenland/', icon: LinkedIn },
		{ url: 'https://medium.com/@zenland', icon: Medium }
	];

	const learnListData = [
		{ url: 'https://zen.land/whitepaper.pdf', name: 'Whitepaper v1.0' },
		{ url: 'https://learn.zen.land/', name: 'Knowledge base' },
		{ url: 'https://zen.land/lightpaper.pdf', name: 'Lightpaper' }
	];

	return (
		<div className={cn(styles.footerLayer, styles.footerLayer1)}>
			<div className={cn(styles.footerLayer, styles.footerLayer2)}>
				<div className={cn(styles.footerLayer, styles.footerLayer3)}>
					<div className={cn(styles.footerLayer, styles.footerLayer4)}>
						<div className={cn(styles.footerLayer, styles.footerLayer5)}>
							<footer
								id='footer'
								className={cn(styles.footer, styles.footerLayer, styles.footerLayer6)}
							>
								<NavLink className={styles.logo} to='/'>
									<SvgIcon className={styles.logoIcon} color='#1A82FB' Icon={LogoImgIcon} />
									<SvgIcon className={styles.desktopOnly} Icon={LogoTextIcon} />
								</NavLink>
								<div className={styles.footerLayout}>
									<article className={cn(styles.footerLayoutItem, styles.footerExplore)}>
										<Heading tag='h6' className='h6'>
											Explore
										</Heading>
										<ul className={styles.list}>
											{exploreListData.map((list, index) => {
												return (
													<li key={index}>
														<a href={list.url} target='_blank' rel='noopener noreferrer'>
															{list.name}
														</a>
													</li>
												);
											})}
										</ul>
									</article>
									<article className={cn(styles.footerLayoutItem, styles.footerLearn)}>
										<Heading tag='h6' className='h6'>
											Learn
										</Heading>
										<ul className={styles.list}>
											{learnListData.map((list, index) => {
												return (
													<li key={index}>
														<a href={list.url} target='_blank' rel='noopener noreferrer'>
															{list.name}
														</a>
													</li>
												);
											})}
										</ul>
									</article>
									<article className={cn(styles.footerLayoutItem, styles.footerCommunicate)}>
										<Heading tag='h6' className='h6'>
											Engage
										</Heading>
										<p className='subtitle'>Join the Zenland community</p>
										<ul className={styles.engageList}>
											{engageListData.map((list, index) => {
												return (
													<li key={index}>
														<a href={list.url} target='_blank' rel='noopener noreferrer'>
															<SvgIcon Icon={list.icon} />
														</a>
													</li>
												);
											})}
										</ul>
										<p className='subtitle'>
											Media inquires for Zenland contact{' '}
											<a href='mailto:info@zen.land' className='subtitle-md'>
												info@zen.land
											</a>
										</p>
									</article>
								</div>
								<div className={styles.footerCopyright}>
									<p className='caption'>
										Copyright © 2022-{new Date().getFullYear()} Zenland. All rights reserved.{' '}
									</p>
									<a
										href='https://zen.land/terms-of-service/'
										target='_blank'
										rel='noopener noreferrer'
										className='caption'
									>
										Terms of Service
									</a>
									<a
										href='https://zen.land/privacy-policy/'
										target='_blank'
										rel='noopener noreferrer'
										className='caption'
									>
										Privacy Policy
									</a>
									<a
										href='https://zen.land/disclaimer/'
										target='_blank'
										rel='noopener noreferrer'
										className='caption'
									>
										Disclaimer
									</a>
								</div>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
