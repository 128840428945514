import { createAsyncThunk } from '@reduxjs/toolkit';
import { Transactions } from '@core/services/transactions';
import { setTransaction } from '@core/store/transaction/transaction.slice';

export const getTransactionThunk = createAsyncThunk<void, number>(
	'transaction/get',
	async (id, { dispatch }) => {
		const transaction = await Transactions.get(id);
		if (transaction) {
			dispatch(setTransaction(transaction));
		}
	}
);
