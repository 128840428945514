import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from '@core/store/alert/alert.slice';
import { appReducer } from '@core/store/app/app.slice';
import { meReducer } from '@core/store/me/me.slice';
import { transactionReducer } from '@core/store/transaction/transaction.slice';
import { vestingReducer } from '@core/store/vesting/vesting.slice';

export const store = configureStore({
	reducer: {
		alert: alertReducer,
		app: appReducer,
		me: meReducer,
		transaction: transactionReducer,
		vesting: vestingReducer
	}
});
