export const baseURL = `https://${process.env.REACT_APP_API_DOMAIN_NAME}:${process.env.REACT_APP_API_PORT}/api/v1`;

export const API = {
	networks: `${baseURL}/networks/`,
	orders: `${baseURL}/orders/`,
	sellRounds: `${baseURL}/sell_rounds/`,
	tokens: `${baseURL}/tokens/`,
	transactions: `${baseURL}/transactions/`,
	waitList: `${baseURL}/wait-list/`,
	vestings: `${baseURL}/vestings/`
};
