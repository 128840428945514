import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { setModal } from '@core/store/app/app.slice';
import { setIsDisabled } from '@core/store/vesting/vesting.slice';
import { getVestingDetails } from '@core/store/vesting/vesting.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Button, Input, Select } from '@components/index';

import styles from './Forms.module.scss';

export const ClaimForm = () => {
	// Redux hooks
	const dispatch = useAppDispatch();
	const [me, allRounds, requestedRound, isLoading, isDisabled] = useAppSelector(
		({ me, app, vesting }) => [
			me,
			app.rounds.data,
			vesting.requestedRound,
			vesting.isLoading,
			vesting.isDisabled
		]
	);

	// React hooks
	const rounds = useMemo(() => {
		return (
			allRounds &&
			allRounds
				.filter(round => round.name !== 'IDO')
				.map(round => ({ name: round.name, value: round.id }))
		);
	}, [allRounds]);
	const [address, setAddress] = useState({ data: '', errorText: '' });
	const [round, setRound] = useState(rounds ? rounds[0] : { name: 'Round I', value: 1 });

	useEffect(() => {
		if (me.address) {
			dispatch(setIsDisabled(false));
			setAddress(prevState => ({ ...prevState, data: me.address }));
		} else {
			dispatch(setIsDisabled(true));
			setAddress(prevState => ({ ...prevState, data: '' }));
		}
	}, [me.address]);

	useEffect(() => {
		if (requestedRound !== round.value) dispatch(setIsDisabled(false));
		else dispatch(setIsDisabled(true));
	}, [round]);

	// Handlers
	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		await dispatch(
			getVestingDetails({
				sellRound: round.value,
				address: address.data
			})
		);
	};

	return (
		<form className={cn(styles.claimForm, styles.form)} onSubmit={handleSubmit}>
			<div className={styles.formInputLabelWrapper}>
				<label className={styles.formInputLabel}>
					<p className={cn('body-md', styles.formInputText)}>Address</p>
					<div className={styles.formInputWrapper}>
						<div className={styles.inputWrapper}>
							<Input
								type='text'
								className={cn(styles.formInput)}
								placeholder='0x...'
								value={address.data}
								onChange={e => setAddress(prevState => ({ ...prevState, data: e.target.value }))}
								disabled
								readOnly
							/>
							{rounds && (
								<Select
									className={cn(styles.roundSelect)}
									options={rounds}
									color='secondary'
									bg='secondary'
									setOption={setRound}
									defaultOption={round}
									listStartSide='end'
								/>
							)}
						</div>
						{me.address ? (
							<Button
								variant='contained'
								size='large'
								color='primary'
								className={styles.submitBtn}
								disabled={isDisabled}
								isLoader={isLoading}
							>
								Get info
							</Button>
						) : (
							<Button
								variant='contained'
								size='large'
								color='primary'
								type='button'
								onClick={() => {
									if (!me.address) dispatch(setModal(true));
								}}
							>
								Get Address
							</Button>
						)}
					</div>
				</label>
				{address.errorText && <p className={styles.errorText}>{address.errorText}</p>}
			</div>
		</form>
	);
};
