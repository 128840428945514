import { useEffect, useState } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import { HashLink as NavLink } from 'react-router-hash-link';

import { MENU_LINKS } from '@core/config/constants';
import { addAlert } from '@core/store/alert/alert.thunks';
import {
	receiveActiveRoundThunk,
	receiveNetworksThunk,
	receiveRoundsThunk,
	receiveTokensThunk
} from '@core/store/app/app.thunks';
import { addAddress } from '@core/store/me/me.slice';
import { setChainId } from '@core/store/me/me.thunks';
import { setRequestedRound, setVesting } from '@core/store/vesting/vesting.slice';
import { getScrollbarWidth } from '@core/utils/helpers/getScrollbarWidth';
import { useAppDispatch, useAppSelector, useWindowMatchMedia } from '@core/utils/hooks';
import { Button, SvgIcon } from '@components/index';
import { ReactComponent as ArrowRightTopIcon } from '@assets/icons/arrows/arrowRightTop.svg';
import { ReactComponent as LogoImgIcon } from '@assets/icons/logo/logo-icon.svg';
import { ReactComponent as LogoTextIcon } from '@assets/icons/logo/logo-text.svg';

import styles from './Header.module.scss';

export const Header = () => {
	//Redux hooks
	const dispatch = useAppDispatch();
	const [activeRoundNotStarted, isOpen, provider] = useAppSelector(({ app, me }) => [
		app.rounds.activeRoundNotStarted,
		app.modal.isOpen,
		me.provider
	]);

	// React hooks
	const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const scrollbarWidth = getScrollbarWidth();
		if (scrollbarWidth)
			document.documentElement.style.setProperty(
				'--scrollbar-width',
				scrollbarWidth.toString() + 'px'
			);
	}, []);

	useEffect(() => {
		const handleChainChanged = (chainId: string | number) => {
			dispatch(setChainId(chainId));
		};

		const handleAccountsChanged = ([account]: string[]) => {
			if (account) dispatch(addAddress(account));
			else dispatch(addAddress(''));

			dispatch(setVesting(null));
			dispatch(setRequestedRound(0));
		};

		const handleMessage = (message: { type: string; data: unknown }) => {
			if (message.type) console.log('message', message.type);
		};

		const handleSessionUpdate = (args: any) => {
			console.log('session_update', args);
		};

		const handleDisconnect = (args: any) => {
			if (args.code === 6000) {
				dispatch(addAddress(''));
				dispatch(addAlert({ text: args.message, type: 'info' }));
			}
		};

		if (window.myProvider) {
			window.myProvider.on('accountsChanged', handleAccountsChanged);
			window.myProvider.on('chainChanged', handleChainChanged);
			window.myProvider.on('message', handleMessage);
			window.myProvider.on('session_update', handleSessionUpdate);
			window.myProvider.on('disconnect', handleDisconnect);
		}

		return () => {
			window.myProvider?.removeListener('accountsChanged', handleAccountsChanged);
			window.myProvider?.removeListener('chainChanged', handleChainChanged);
			window.myProvider?.removeListener('message', handleMessage);
			window.myProvider?.removeListener('session_update', handleSessionUpdate);
			window.myProvider?.removeListener('disconnect', handleDisconnect);
		};
	}, [provider, window.myProvider]);

	useEffect(() => {
		dispatch(receiveNetworksThunk());
		dispatch(receiveTokensThunk());
		dispatch(receiveRoundsThunk());
		dispatch(receiveActiveRoundThunk());
	}, [activeRoundNotStarted]);

	// Custom hooks
	useWindowMatchMedia(1200, setIsMobile);

	return (
		<header className={cn(styles.header, { 'y-scroll-hidden': isOpen })}>
			<nav className={styles.nav}>
				<NavLink className={styles.logo} to='/'>
					<SvgIcon className={styles.logoIcon} color='#1A82FB' Icon={LogoImgIcon} />
					<SvgIcon className={styles.desktopOnly} Icon={LogoTextIcon} />
				</NavLink>
				<AnimateHeight
					duration={100}
					className={cn(styles.menuWrapper, styles.mobileOnly, {
						[styles.active]: isMobileMenuActive
					})}
					height={isMobile ? (isMobileMenuActive ? 'auto' : 0) : 'auto'}
				>
					<ul className={cn(styles.menu, 'h6')}>
						{MENU_LINKS.map((menu, index) => {
							return (
								<li key={index}>
									<NavLink
										to={menu.link}
										className={styles.menuLink}
										scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
										onClick={() => {
											if (isMobile) setIsMobileMenuActive(false);
										}}
									>
										{menu.name}
									</NavLink>
								</li>
							);
						})}
						<li>
							<Button
								as='externalLink'
								className={cn(styles.goToLanding, styles.menuLink, 'h6')}
								href='https://zen.land/'
								size='medium'
								color='primary'
							>
								Zenland
								<SvgIcon Icon={ArrowRightTopIcon} />
							</Button>
						</li>
					</ul>
					<Button
						as='externalLink'
						className={styles.goToApp}
						href='https://app.zen.land/'
						variant='contained'
						size='medium'
						color='primary'
					>
						Launch App
					</Button>
				</AnimateHeight>
				<Button
					className={cn(styles.hamburger, styles.mobileOnly, {
						[styles.active]: isMobileMenuActive
					})}
					onClick={() => setIsMobileMenuActive(prevState => !prevState)}
				>
					<span className={cn(styles.hamburgerInner)}></span>
				</Button>
			</nav>
		</header>
	);
};
