import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';
import Benefits from '@assets/img/utility/benefits.png';
import ReducesFees from '@assets/img/utility/reduces_fee.png';
import Rewards from '@assets/img/utility/rewards.png';

import styles from './Utility.module.scss';

export const Utility = () => {
	const utilityData = [
		{
			title: 'Reduced fees',
			content:
				'ZENF is used to pay the service fee for both escrow and marketplace contracts (and all other released types of contracts within the Zenland ecosystem). Adding ZENF to their internal wallets, platform users get a 50% bonus to their balance.',
			img: ReducesFees
		},
		{
			title: 'Community rewards',
			content:
				'Early adopters of the Zenland platform (web3 escrow & marketplace) are rewarded with ZENF tokens. It is also used for contributor programs (hackathons, bug bounties, airdrops, etc.) to grow the overall value of the project and its token.',
			img: Rewards
		},
		{
			title: 'DAO TGE benefits',
			content:
				'When the Zenland DAO community is formed, all ZENF token holders will have priority access to governance token generation events and other DAO member benefits, including voting for improvement proposals and profit sharing.',
			img: Benefits
		}
	];

	return (
		<section className={styles.utility} id='utility'>
			<div className={cn('container', styles.container)}>
				<Heading tag='h2' className={cn('containerTitle', 'h3', styles.containerTitle)}>
					ZENF utility
				</Heading>
				<div className={styles.blocks}>
					{utilityData.map((data, index) => {
						return (
							<article className={cn(styles.block, 'shadowedBlock')} key={index}>
								<div className={styles.blockInfo}>
									<Heading tag='h4' className='h4'>
										{data.title}
									</Heading>
									<p className='subtitle'>{data.content}</p>
								</div>
								<img src={data.img} alt={data.title} />
							</article>
						);
					})}
				</div>
			</div>
		</section>
	);
};
