import React from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import { removeAlertAction } from '@core/store/alert/alert.slice';
import { AlertType } from '@core/store/alert/alert.types';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Button, SvgIcon } from '@components/index';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/status/success-outlined.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/status/warning-circle.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/status/warning-outlined-triangle.svg';

import { AlertProps } from './Alert.props';

import styles from './Alert.module.scss';

export const Alert = ({ className }: AlertProps) => {
	/* Redux hooks */
	const alerts = useAppSelector(({ alert }) => alert);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleClick = (id: string) => () => {
		dispatch(removeAlertAction(id));
	};

	const renderIcon = (type: AlertType) => {
		switch (type) {
			case 'error': {
				return <SvgIcon className={styles.alertIcon} Icon={ErrorIcon} />;
			}
			case 'info': {
				return <SvgIcon className={styles.alertIcon} Icon={InfoIcon} />;
			}
			case 'success': {
				return <SvgIcon className={styles.alertIcon} Icon={SuccessIcon} />;
			}
		}
	};

	const renderNotifications = () => {
		return alerts.map(item => (
			<div
				key={item.id}
				className={cn(
					styles.alert,
					className,
					styles[item.type],
					{
						[styles.active]: item.id
					},
					'body-sm'
				)}
				aria-live='polite'
				aria-atomic='true'
			>
				{renderIcon(item.type)}
				<p className={styles.content}>{parse(item.text)}</p>
				{item.isSubmit && (
					<Button className={styles.btn} onClick={handleClick(item.id)} type='button'>
						<SvgIcon Icon={CrossIcon} />
					</Button>
				)}
			</div>
		));
	};

	return (
		<div
			className={cn(styles.wrapper, {
				[styles.active]: alerts.length
			})}
		>
			{renderNotifications()}
		</div>
	);
};
