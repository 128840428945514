import { API } from '@core/config/api';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';
import { htmlTagsPattern } from '@core/utils/regex';
import { HandlerResponse } from '@components/LandingComponents/Forms/handlers/handlers.types';
import { IInvestmentForm } from '@sections/Investment/Investment.type';

export const waitlistHandler = async (data: IInvestmentForm): Promise<HandlerResponse | void> => {
	const result = (failText = 'Error'): Record<string, HandlerResponse> => {
		return {
			success: {
				isButtonDisabled: true,
				response: {
					text: 'Done! You will receive an email update when presale starts.',
					status: 'success'
				}
			},
			fail: {
				isButtonDisabled: false,
				response: {
					text: failText,
					status: 'danger'
				}
			}
		};
	};

	const { email, amount, sell_round, network, token } = data;
	const bodyData = {
		email,
		network,
		token,
		amount,
		sell_round
	};

	try {
		const res = await fetch(`${API.waitList}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': getCookie('csrftoken')
			},
			credentials: 'include',
			body: JSON.stringify(bodyData)
		});

		if (!res.ok) {
			const error = await res.json();
			const errorString = logErrors(error);
			if (errorString) return result(errorString.replace(htmlTagsPattern, '')).fail;
		}

		await res.json();

		return result().success;
	} catch (e) {
		logErrors(e);
		return result().fail;
	}
};
