import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProviderEnum } from '@core/models/enums/provider.enum';

import { IInitialState } from './me.types';

export const initialState: IInitialState = {
	address: '',
	chainId: '',
	provider: {
		isLoading: false,
		name: null
	}
};

const meSlice = createSlice({
	name: 'me',
	initialState,
	reducers: {
		addAddress: (state, action: PayloadAction<string>) => {
			state.address = action.payload;
		},
		addChainId: (state, action: PayloadAction<string>) => {
			state.chainId = action.payload;
		},
		addProviderName: (state, action: PayloadAction<ProviderEnum | null>) => {
			state.provider.name = action.payload;
		},
		addProviderLoading: (state, action: PayloadAction<boolean>) => {
			state.provider.isLoading = action.payload;
		}
	}
});

export const {
	reducer: meReducer,
	actions: { addAddress, addChainId, addProviderName, addProviderLoading }
} = meSlice;
