import { API } from '@core/config/api';
import { INetwork } from '@core/models/interfaces/networkInterfaces';

export const Networks: { getAll: () => Promise<INetwork[]> } = {
	async getAll() {
		try {
			const response = await fetch(`${API.networks}?is_active=true`);
			if (!response.ok) return console.log(await response.json());
			return await response.json();
		} catch (e) {
			console.log(e);
			return false;
		}
	}
};
