import React from 'react';
import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';
import {
	ChartLegendsDataType,
	HoveredItemIndexType
} from '@sections/Allocation/Widgets/Widgets.types';

import styles from './Widgets.module.scss';

export const ChartLegends = ({
	chartData,
	activeChartDataIndex,
	setHoveredLegend
}: {
	chartData: ChartLegendsDataType;
	activeChartDataIndex: HoveredItemIndexType;
	setHoveredLegend: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const getPercent = (percent: number, total: number) => {
		return (percent * 0.01 * total).toLocaleString();
	};

	return (
		<div className={styles.chartLegends}>
			{chartData &&
				chartData.data.map((legend, i) => {
					return (
						<div
							key={i}
							className={cn(styles.chartLegend, { [styles.active]: activeChartDataIndex === i })}
							onMouseEnter={() => setHoveredLegend(i)}
							onMouseLeave={() => setHoveredLegend(-1)}
						>
							<div>
								<Heading tag='h6' className='h6'>
									{legend.title}
								</Heading>
								<p className='subtitle-md'>
									{getPercent(parseInt(legend.title), chartData.totalSupply)} zenf
								</p>
							</div>
							<small className={'subtitle'}>{legend.info}</small>
						</div>
					);
				})}
		</div>
	);
};
