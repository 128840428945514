import { useState } from 'react';
import cn from 'classnames';

import { useWindowMatchMedia } from '@core/utils/hooks';
import { Heading } from '@components/MainComponents/Heading/Heading';
import Hero from '@assets/img/hero.png';
import { PresaleTimer } from '@sections/Token/Widgets/PresaleTimer';

import styles from './Token.module.scss';

export const Token = () => {
	// React hooks
	const [isMobile, setIsMobile] = useState(false);

	// Custom hooks
	useWindowMatchMedia(769, setIsMobile);

	return (
		<section className={styles.token} id='home'>
			<div className={cn('container', styles.container)}>
				<div className={styles.leftSide}>
					<Heading tag='h1' className={cn('h1', styles.title)}>
						WHAT IS <span>ZENF</span> TOKEN
					</Heading>
					<p className={cn('h6', styles.description)}>
						Zenland Fee (ZENF) is an ERC-20 utility token of the Zenland smart contract platform.
						Token integration with the escrow platform and its deflationary model ensures robust
						tokenomics and its value-driven utility.
					</p>
					{isMobile || <PresaleTimer />}
				</div>
				<div className={styles.rightSide}>
					<img src={Hero} alt='hero' className={styles.tokenImg} />
					{isMobile && <PresaleTimer />}
				</div>
			</div>
		</section>
	);
};
