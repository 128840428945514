import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import Moment from 'react-moment';

import { claimContract } from '@core/services/claim/web3Calls';
import { IVesting } from '@core/services/vestings/models';
import { setProviderChainId } from '@core/store/me/me.thunks';
import { getVestingDetails } from '@core/store/vesting/vesting.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { logErrors } from '@core/utils/logErrors';
import { Badge } from '@components/MainComponents/Badge/Badge';
import { Button } from '@components/MainComponents/Button/Button';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { Timer } from '@components/MainComponents/Timer/Timer';
import { ReactComponent as ZenfToken } from '@assets/icons/tokens/zen_token.svg';

import styles from './Widgets.module.scss';

export const VestingDetails = ({ data, round }: { data: IVesting; round: number }) => {
	// Redux hooks
	const dispatch = useAppDispatch();
	const [address, chainId, rounds, networks, vesting] = useAppSelector(({ me, app, vesting }) => [
		me.address,
		me.chainId,
		app.rounds,
		app.networks,
		vesting
	]);

	// Constants
	const { contractAddress, contractNetwork } = useMemo(() => {
		const selectedRound = rounds.data?.find(r => r.id === round);
		const network = networks?.find(n => n.id === selectedRound?.vesting_contract_network);

		return {
			contractAddress: selectedRound?.vesting_contract_address,
			contractNetwork: network?.id
		};
	}, [round]);

	// React hooks
	const [buttonState, setButtonState] = useState({ isDisabled: false, isLoading: false });
	const [formSubmitResponse, setFormSubmitResponse] = useState<{
		text: string;
		status: 'danger' | 'success' | 'warn';
	} | null>(null);

	const vestingStartTime = useMemo(() => {
		let _leftToStartSeconds = 0;
		let _vestingStartDate = 0;
		const startDate = (data.vesting_creation_timestamp + data.cliff_duration) * 1000;
		const isExpired = new Date().getTime() - startDate;

		if (isExpired > 0)
			_leftToStartSeconds = new Date().getTime() - startDate + data.vesting_duration * 1000;
		else _leftToStartSeconds = startDate - new Date().getTime();

		_vestingStartDate = new Date().getTime() + _leftToStartSeconds;
		return {
			leftSeconds: Math.round(_leftToStartSeconds / 1000),
			isExpired: isExpired > 0,
			startDate: isExpired > 0 ? 0 : _vestingStartDate
		};
	}, [data]);

	// Handlers
	const handleChangeChainId = async (networkId: number) => {
		if (networks) {
			const network = networks.find(n => n.id === networkId);
			if (network) {
				if (chainId === network.chain_id) return true;
				else {
					const { chain_id, metamask_name, rpc, explorer_url, decimals, symbol } = network;
					return dispatch(
						setProviderChainId({
							chainName: metamask_name,
							chainId: chain_id,
							decimals,
							rpcUrls: [rpc],
							symbol,
							blockExplorerUrls: [explorer_url]
						})
					);
				}
			}
		}
	};
	const handleClaim = async () => {
		if (contractAddress && contractNetwork) {
			setButtonState({ isDisabled: true, isLoading: true });

			try {
				const networkChanged = await handleChangeChainId(contractNetwork);

				if (!networkChanged) return;

				const res = await claimContract({ contractAddress, contractNetwork, address });

				if (res) {
					setFormSubmitResponse({
						text: 'Done! You successfully claimed you ZENF tokens',
						status: 'success'
					});
					await dispatch(getVestingDetails({ sellRound: vesting.requestedRound, address }));
				}
			} catch (e) {
				logErrors(e);
			}
			setButtonState({ isDisabled: false, isLoading: false });
		}
	};

	return (
		<div className={styles.detailsWrapper}>
			<p className='body-md'>{round} vesting information</p>
			<dl className={cn(styles.details, 'subtitle')}>
				<div>
					<dt>Cliff duration (3 Month)</dt>
					<dd>
						<Timer
							sec={
								data.vesting_creation_timestamp +
								data.cliff_duration -
								Math.round(new Date().getTime() / 1000)
							}
							variant='minimal'
						/>
					</dd>
				</div>
				<div>
					<dt>Vesting (3 Month)</dt>
					<dd>
						{vestingStartTime.isExpired ? (
							<Timer sec={vestingStartTime.leftSeconds} variant='minimal' />
						) : (
							<>
								Starts at <Moment format='MMMM D'>{vestingStartTime.startDate}</Moment>
							</>
						)}
					</dd>
				</div>
				<div>
					<dt>Purchased amount</dt>
					<dd>
						{(+data.purchased_amount).toFixed(2)} <SvgIcon Icon={ZenfToken} />
					</dd>
				</div>
				<div>
					<dt>Claimed</dt>
					<dd>
						{(+data.claimed).toFixed(2)} ({data.claimed_percentage}%) <SvgIcon Icon={ZenfToken} />
					</dd>
				</div>
				<div>
					<dt>Available</dt>
					<dd>
						{(+data.available).toFixed(2)} ({data.available_percentage}%){' '}
						<SvgIcon Icon={ZenfToken} />
					</dd>
				</div>
				<div className={styles.progressDetail}>
					<dt>Vesting progress</dt>
					<dd>{data.vesting_progress}%</dd>
					<span className={cn(styles.progressBar, 'progressBar')}>
						<span className={'progressBarActive'} style={{ width: `${data.vesting_progress}%` }} />
					</span>
				</div>
			</dl>
			<Button
				variant='contained'
				size='large'
				color='primary'
				className={styles.claimBtn}
				type='button'
				onClick={handleClaim}
				disabled={buttonState.isDisabled || (+data.available).toFixed(2) === '0.00'}
				isLoader={buttonState.isLoading}
			>
				Claim ZENF
			</Button>
			{formSubmitResponse && (
				<Badge
					size='md'
					hasIcon
					color={formSubmitResponse.status}
					className={cn(styles.submitMessage, 'body-sm')}
				>
					<p>
						{Array.isArray(formSubmitResponse.text)
							? parse(formSubmitResponse.text[0])
							: parse(formSubmitResponse.text)}
					</p>
				</Badge>
			)}
		</div>
	);
};
