import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChangeNetworksProps } from '@core/services/networks/models';
import { changeNetworkWeb3 } from '@core/services/networks/web3Calls';
import { addAddress, addChainId, addProviderName } from '@core/store/me/me.slice';
import { setRequestedRound, setVesting } from '@core/store/vesting/vesting.slice';
import { toHex } from '@core/utils/helpers';

export const setChainId = createAsyncThunk<void, number | string>(
	'me/setChainId',
	async (chainId, { dispatch }) => {
		if (typeof chainId === 'number') dispatch(addChainId(toHex(chainId)));
		else dispatch(addChainId(chainId));
	}
);

export const setProviderChainId = createAsyncThunk<boolean, ChangeNetworksProps>(
	'me/setProviderChainId',
	async (networkData, { dispatch }) => {
		const { chainId, chainName, rpcUrls, blockExplorerUrls, symbol, decimals } = networkData;

		const changeNetwork = await changeNetworkWeb3({
			chainName: chainName,
			chainId: chainId,
			decimals,
			symbol,
			blockExplorerUrls: blockExplorerUrls,
			rpcUrls: rpcUrls
		});

		if (changeNetwork) {
			dispatch(addChainId(chainId));
			return true;
		}
		return false;
	}
);

export const logoutThunk = createAsyncThunk('me/logout', async (_, { dispatch }) => {
	dispatch(addAddress(''));
	dispatch(addProviderName(null));
	dispatch(setVesting(null));
	dispatch(setRequestedRound(0));
	window.myProvider = null;
	window.myWeb3 = null;
});
