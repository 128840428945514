import { useEffect, useRef } from 'react';
import cn from 'classnames';

import { setModal } from '@core/store/app/app.slice';
import { useAppDispatch, useOnClickOutside } from '@core/utils/hooks';
import { Button } from '@components/MainComponents/Button/Button';

import { TModal, TModalAttributes } from './Modal.props';

import styles from './Modal.module.scss';

export const Modal = ({
	children,
	isActive = false,
	showCloseBtn = false,
	className
}: TModal & TModalAttributes) => {
	// Redux hooks
	const dispatch = useAppDispatch();

	// React hooks
	const ref = useRef(null);

	useEffect(() => {
		if (isActive) document.body.classList.add('y-scroll-hidden');
		else document.body.classList.remove('y-scroll-hidden');
	}, [isActive]);

	// Handlers
	const handleClose = () => dispatch(setModal(false));

	// Custom hooks
	useOnClickOutside(ref, handleClose);

	return (
		<div className={cn(styles.modalOverlay, { [styles.active]: isActive })}>
			<div className={cn(styles.modal, className)} role='dialog' aria-modal tabIndex={-1} ref={ref}>
				{children}
				{showCloseBtn && (
					<Button className={styles.modalClose} onClick={handleClose}>
						Close
					</Button>
				)}
			</div>
		</div>
	);
};
