import Web3 from 'web3';

import { tokenABI } from '@core/config/tokenABI';
import { IOrders } from '@core/services/orders/index';
import { Transactions } from '@core/services/transactions';
import { TransactionPostResponse } from '@core/services/transactions/models';
import { store } from '@core/store/store';
import { setIsConfirmingTransaction } from '@core/store/transaction/transaction.slice';
import { gasOptions } from '@core/utils/helpers/getGasOptions';
import { toHex } from '@core/utils/helpers/toHex';
import { logErrors } from '@core/utils/logErrors';
import { getGasPrice } from '@core/utils/web3/getGasPrice';

export const orderZenf = async ({
	amount,
	address,
	receiver_address,
	network,
	id,
	tokenData
}: IOrders & { id: number; receiver_address: string }) => {
	try {
		const web3 = new Web3(window.myProvider);

		const tokenInstance = new web3.eth.Contract(tokenABI, tokenData.contract_address);

		const gas = await tokenInstance.methods
			.transfer(receiver_address, toHex(+amount * 10 ** tokenData.decimals))
			.estimateGas({ from: web3.utils.toChecksumAddress(address) }, (err: Error, gas: number) => {
				return gas;
			});

		let _res: TransactionPostResponse | string | undefined;

		await tokenInstance.methods
			.transfer(receiver_address, toHex(+amount * 10 ** tokenData.decimals))
			.send({
				from: address,
				...(await gasOptions(gas, 'Buy Zenf', network))
			})
			.on('transactionHash', async (txHash: string) => {
				store.dispatch(setIsConfirmingTransaction(true));
				_res = await Transactions.post({
					tx_hash: txHash,
					order: id.toString()
				});
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});
		if (_res) return _res;
	} catch (error) {
		logErrors(error);
	}
};
