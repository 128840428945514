import { API } from '@core/config/api';
import { TransactionPostParams, TransactionPostResponse } from '@core/services/transactions/models';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Transactions = {
	async post(body: TransactionPostParams): Promise<TransactionPostResponse | undefined | string> {
		try {
			const response = await fetch(API.transactions, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify(body)
			});
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async get(id: number): Promise<TransactionPostResponse | undefined> {
		try {
			const response = await fetch(`${API.transactions}${id}/`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			if (!response.ok) logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};
