import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IVesting } from '@core/services/vestings/models';
import { VestingInitialState } from '@core/store/vesting/vesting.types';

export const initialState: VestingInitialState = {
	data: null,
	requestedRound: 0,
	isDisabled: false,
	isLoading: false
};

export const vestingSlice = createSlice({
	name: 'vesting',
	initialState,
	reducers: {
		setVesting: (state, action: PayloadAction<IVesting | null>) => {
			state.data = action.payload;
		},
		setRequestedRound: (state, action: PayloadAction<number>) => {
			state.requestedRound = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setIsDisabled: (state, action: PayloadAction<boolean>) => {
			state.isDisabled = action.payload;
		}
	}
});

export const {
	reducer: vestingReducer,
	actions: { setVesting, setRequestedRound, setIsLoading, setIsDisabled }
} = vestingSlice;
