import Web3 from 'web3';

import { multiVestingABI } from '@core/config/multiVestingABI';
import { gasOptions } from '@core/utils/helpers';
import { logErrors } from '@core/utils/logErrors';

export const claimContract = async ({
	contractAddress,
	address,
	contractNetwork
}: {
	contractAddress: string;
	address: string;
	contractNetwork: number;
}) => {
	const web3 = new Web3(window.myProvider);
	const contractInstance = new web3.eth.Contract(multiVestingABI, contractAddress);
	const gas = await contractInstance.methods
		.withdraw()
		.estimateGas({ from: web3.utils.toChecksumAddress(address) }, (err: Error, gas: number) => {
			return gas;
		});

	return await contractInstance.methods
		.withdraw()
		.send({ from: address, ...(await gasOptions(gas, 'Claim Zenf', contractNetwork)) })
		.on('error', (error: Error) => {
			logErrors(error);
		});
};
