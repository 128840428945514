import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';
import { Accordion } from '@sections/Faq/Widgets/Accordion';

import styles from './Faq.module.scss';

export const Faq = () => {
	return (
		<section className={cn(styles.faq)} id='faq'>
			<div className={cn(styles.container, 'container')}>
				<Heading tag='h3' className={cn('h3', 'containerTitle', styles.containerTitle)}>
					FAQ
				</Heading>
				<Accordion />
			</div>
		</section>
	);
};
