import { API } from '@core/config/api';
import { logErrors } from '@core/utils/logErrors';

export const Rounds = {
	async getAll() {
		try {
			const response = await fetch(`${API.sellRounds}`);
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
			return false;
		}
	},
	async getActive() {
		try {
			const response = await fetch(`${API.sellRounds}?is_active=true`);
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
			return false;
		}
	}
};
