import { getGasPrice } from '@core/utils/web3/getGasPrice';

export const gasOptions = async (gas: number, step: string, network: number) => {
	console.log(
		`%c ${step} gas estimation ` + gas + ' units',
		'color:green;border:1px solid dodgerblue;padding-right:5px'
	);

	const gasPrice = await getGasPrice(network);

	if (gasPrice) {
		console.log(
			`%c ${step} gas price ${gasPrice} wei`,
			'color:brown;border:1px solid dodgerblue;padding-right:5px'
		);
		const gasEstimation = gas * +gasPrice;
		console.log(
			`%c ${step} gas cost estimation ${gasEstimation} wei`,
			'color:purple;border:1px solid dodgerblue;padding-right:5px'
		);
		return { gas, gasPrice };
	} else {
		return { gas };
	}
};
