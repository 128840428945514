import { Allocation } from '@sections/Allocation/Allocation';
import { Claim } from '@sections/Claim/Claim';
import { Faq } from '@sections/Faq/Faq';
import { Information } from '@sections/Information/Information';
import { Investment } from '@sections/Investment/Investment';
import { Roadmap } from '@sections/Roadmap/Roadmap';
import { Token } from '@sections/Token/Token';
import { Utility } from '@sections/Utility/Utility';

export const Sections = () => {
	return (
		<>
			<Token />
			<Utility />
			<Allocation />
			<Roadmap />
			<Information />
			<Faq />
			<Investment />
			<Claim />
		</>
	);
};
