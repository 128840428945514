import cn from 'classnames';

import { useAppSelector } from '@core/utils/hooks';
import { ClaimForm } from '@components/LandingComponents/Forms/ClaimForm';
import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { VestingDetails } from '@sections/Claim/Widgets/VestingDetails';

import styles from './Claim.module.scss';

export const Claim = () => {
	const [vesting, round] = useAppSelector(({ vesting }) => [vesting.data, vesting.requestedRound]);

	return (
		<section className={cn(styles.claim)} id='claim'>
			<div className={cn('container', styles.container, 'shadowedBlock')}>
				<Heading tag='h3' className='h3'>
					Claim your ZENF token
				</Heading>
				<p className={cn('body-sm', styles.claimInfo)}>
					Dear investors, you can claim your ZENF allocation only when vesting period starts (a.i.
					after 3-month cliff period). This date is different for every Presale round. You can see
					when it starts in the &quot;Be an early investor&quot; form above.
				</p>
				<p className={cn('body-sm', styles.claimInfo)}>
					Before it starts, you will be able to check the address and amount in vesting contract
					(for Round III investors from <span className='body-md'>July 15</span> ) in this form.{' '}
					<Button
						as='externalLink'
						href='https://learn.zen.land/kb/how-can-i-claim-the-zenf-tokens-i-purchased-on-presale/'
						className={styles.claimInfoLink}
					>
						Read how to claim &#8594;
					</Button>
				</p>
				<ClaimForm />
				{vesting && <VestingDetails data={vesting} round={round} />}
			</div>
		</section>
	);
};
