import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TransactionPostResponse } from '@core/services/transactions/models';

export const initialState: {
	data: TransactionPostResponse | null;
	isConfirmingTransaction: boolean;
} = {
	data: null,
	isConfirmingTransaction: false
};

export const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		setTransaction: (state, action: PayloadAction<TransactionPostResponse>) => {
			state.data = action.payload;
		},
		setIsConfirmingTransaction: (state, action: PayloadAction<boolean>) => {
			state.isConfirmingTransaction = action.payload;
		}
	}
});

export const {
	reducer: transactionReducer,
	actions: { setTransaction, setIsConfirmingTransaction }
} = transactionSlice;
