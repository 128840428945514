import { API } from '@core/config/api';
import { IVesting } from '@core/services/vestings/models';
import { VestingTypes } from '@core/store/vesting/vesting.types';
import { logErrors } from '@core/utils/logErrors';

export const Vestings = {
	async get({ sellRound, address }: VestingTypes): Promise<IVesting | string | undefined> {
		try {
			const response = await fetch(`${API.vestings}?sell_round=${sellRound}&address=${address}`);
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			return logErrors(e);
		}
	}
};
