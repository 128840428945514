import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProviderEnum } from '@core/models/enums/provider.enum';
import { declareGlobalProvider, declareGlobalWeb3 } from '@core/services/auth/web3Calls';
import { Networks } from '@core/services/networks';
import { IOrders, Orders } from '@core/services/orders';
import { orderZenf } from '@core/services/orders/web3Calls';
import {
	enableSelectedProvider,
	getProviderChainId,
	getSelectedProvider
} from '@core/services/providers';
import { Rounds } from '@core/services/rounds';
import { Tokens } from '@core/services/tokens';
import { setActiveRound, setNetworks, setRounds, setTokens } from '@core/store/app/app.slice';
import { addAddress, addProviderLoading, addProviderName } from '@core/store/me/me.slice';
import { setChainId } from '@core/store/me/me.thunks';
import { setTransaction } from '@core/store/transaction/transaction.slice';
import { logErrors } from '@core/utils/logErrors';

export const receiveNetworksThunk = createAsyncThunk(
	'app/receiveNetworks',
	async (_, { dispatch }) => {
		const networks = await Networks.getAll();
		if (networks) dispatch(setNetworks(networks));
	}
);

export const receiveTokensThunk = createAsyncThunk('app/receiveTokens', async (_, { dispatch }) => {
	const tokens = await Tokens.getAll();
	if (tokens) dispatch(setTokens(tokens));
});

export const receiveRoundsThunk = createAsyncThunk('app/receiveRounds', async (_, { dispatch }) => {
	const rounds = await Rounds.getAll();
	if (rounds) dispatch(setRounds(rounds));
});

export const receiveActiveRoundThunk = createAsyncThunk(
	'app/receiveActiveRound',
	async (_, { dispatch }) => {
		const [round] = await Rounds.getActive();
		if (round) dispatch(setActiveRound(round));
	}
);

export const setProviderThunk = createAsyncThunk<
	void,
	{ providerName: string; chainId: number; chainNumbers: number[] }
>('app/setProvider', async ({ providerName, chainId, chainNumbers }, { dispatch }) => {
	// get provider
	const provider = await getSelectedProvider(providerName, chainId, chainNumbers);

	if (provider) {
		declareGlobalProvider(provider);
		declareGlobalWeb3(provider);

		// enable provider
		dispatch(addProviderLoading(true));
		// get address
		const address = await enableSelectedProvider(providerName);
		// get chain id
		const network = address && (await getProviderChainId());

		if (address) dispatch(addAddress(address));
		if (network) dispatch(setChainId(network));

		dispatch(addProviderName(providerName as ProviderEnum));
		dispatch(addProviderLoading(false));
	}
});

export const buyZenfThunk = createAsyncThunk<void, IOrders>(
	'order/buyZenf',
	async (data, { dispatch }) => {
		try {
			const order = await Orders.post(data);

			// not return dispatch
			if (order && typeof order === 'object') {
				const contractInteraction = await orderZenf({ ...data, ...order });
				if (typeof contractInteraction !== 'string' && contractInteraction?.status === 'pending') {
					dispatch(setTransaction(contractInteraction));
				}
			}
		} catch (e) {
			logErrors(e);
		}
	}
);
