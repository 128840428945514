import { Dispatch, SetStateAction } from 'react';
import {
	ArcElement,
	Chart,
	Chart as ChartJS,
	ChartTypeRegistry,
	Legend,
	Tooltip,
	TooltipItem,
	Plugin
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import styles from './Widgets.module.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

// Define the type for hoveredItemIndex
type HoveredItemIndexType = number;

export const DoughnutChart = ({
	setItemIndex
}: {
	setItemIndex: Dispatch<SetStateAction<HoveredItemIndexType>>;
	hoveredLegend: number;
}) => {
	const data = {
		labels: [
			'Marketing',
			'Development',
			'Presale',
			'Team',
			'Community',
			'IDO',
			'Early adopters',
			'Treasury'
		],
		datasets: [
			{
				data: [21, 18, 18, 15, 10, 8, 5, 5],
				backgroundColor: '#6EB1FF',
				borderRadius: 20,
				borderWidth: 0,
				hoverBackgroundColor: '#3C97FF',
				spacing: 20
			}
		]
	};

	const options = {
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: 'Doughnut Chart',
				color: 'blue',
				font: {
					size: 44
				},
				padding: {
					top: 30,
					bottom: 30
				},
				responsive: true,
				animation: {
					animateScale: true,
					color: true
				}
			},
			tooltip: {
				enabled: true,
				backgroundColor: 'rgba(255, 255, 255, 1)',
				textAlign: 'center' as const,
				bodyFont: {
					weight: 'bold' as const,
					size: 26,
					family: 'Creato Display'
				},
				bodyColor: '#19192C',
				displayColors: false,
				caretPadding: 40,
				footerColor: '#535361',
				footerFont: {
					weight: 'bold' as const,
					size: 16,
					family: 'Creato Display'
				},
				callbacks: {
					title: () => '',
					label: (context: TooltipItem<keyof ChartTypeRegistry>) => {
						return context.formattedValue + '%';
					},
					footer: (context: TooltipItem<keyof ChartTypeRegistry>[]) => {
						return context[0].label;
					}
				}
			}
		},
		maintainAspectRatio: false,
		cutout: '88%'
	};

	const textCenter: Plugin<'doughnut'> = {
		id: 'textCenter',
		beforeDatasetsDraw(chart: Chart<'doughnut'>, _args, _options) {
			const { ctx } = chart;
			ctx.save();

			ctx.font = '600 26px Creato Display';
			ctx.fillStyle = '#19192C';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(
				'200 000 000 ZENF',
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y - 12
			);
			ctx.restore();

			ctx.font = '500 18px Creato Display';
			ctx.fillStyle = '#535361';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(
				'Total supply',
				chart.getDatasetMeta(0).data[0].x,
				chart.getDatasetMeta(0).data[0].y + 12
			);
			ctx.restore();
		}
	};

	const setActiveIndex: Plugin<'doughnut'> = {
		id: 'setActiveIndex',
		afterDraw(chart: Chart<'doughnut'>) {
			const { tooltip } = chart;
			if (tooltip?.opacity) {
				if (tooltip?.dataPoints[0] && tooltip?.dataPoints[0].dataIndex >= 0) {
					setItemIndex(tooltip?.dataPoints[0].dataIndex);
				}
			} else {
				setItemIndex(-1);
			}
		}
	};

	return (
		<div className={styles.doughnutWrapper}>
			<Doughnut
				data={data}
				options={options}
				className={styles.doughnut}
				plugins={[textCenter, setActiveIndex]}
			/>
		</div>
	);
};
