import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { ReactComponent as ChevronDown } from '@assets/icons/arrows/chevron-down.svg';
import { FAQ } from '@sections/Faq/Widgets/Widgets.types';

import styles from './Widgets.module.scss';

export const AccordionItem = ({
	data,
	onToggle,
	active
}: {
	data: FAQ;
	onToggle: () => void;
	active: boolean;
}) => {
	return (
		<div className={cn(styles.item, 'shadowedBlock')}>
			<Button className={cn(styles.question)} onClick={onToggle}>
				<Heading tag='h5' className='h5'>
					{data.question}
				</Heading>
				<SvgIcon Icon={ChevronDown} className={cn({ [styles.notActive]: !active })} />
			</Button>
			<AnimateHeight
				duration={600}
				className={cn(styles.answer, { [styles.open]: active })}
				height={active ? 'auto' : 0}
				style={{ transition: 'height 0.6s ease, margin 0.6s ease' }}
			>
				<p className='subtitle'>{data.answer}</p>
			</AnimateHeight>
		</div>
	);
};
