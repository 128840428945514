import { API } from '@core/config/api';

export const Tokens = {
	async getAll() {
		try {
			const response = await fetch(`${API.tokens}?is_active=true`);
			if (!response.ok) return console.log(await response.json());
			return await response.json();
		} catch (e) {
			console.log(e);
			return false;
		}
	},
	async getOne(id: number) {
		try {
			const response = await fetch(`${API.tokens}?is_active=true&network=${id}`);
			if (!response.ok) return console.log(await response.json());
			return await response.json();
		} catch (e) {
			console.log(e);
			return false;
		}
	}
};
