import React from 'react';
import cn from 'classnames';

import { ReactComponent as InfoIcon } from '@assets/icons/status/info-outlined-circle.svg';

import styles from './SvgIcon.module.scss';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const SvgIcon: React.FC<SvgProps> = ({ Icon, className, ...props }) => (
	<Icon
		className={cn(styles.icon, className, {
			[styles.infoIcon]: Icon === InfoIcon
		})}
		{...props}
	/>
);
