import React from 'react';
import cn from 'classnames';

import { SvgIcon } from '@components/index';
import { ReactComponent as PendingIcon } from '@assets/icons/status/pending.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/status/success-outlined.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/status/warning-outlined-circle.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/status/warning-outlined-triangle.svg';

import { BadgeProps } from './Badge.props';

import styles from './Badge.module.scss';

export const Badge = ({
	color = '',
	text = '',
	size = 'md',
	rounded = 'sm',
	hasIcon,
	className,
	children,
	fullWidth = false,
	...props
}: BadgeProps) => {
	let Icon = SuccessIcon;

	if (color === 'warn') {
		Icon = WarningIcon;
	} else if (color === 'danger') {
		Icon = ErrorIcon;
	} else if (color === 'await') {
		Icon = PendingIcon;
	}

	return (
		<div
			className={cn(
				styles.badge,
				styles[color],
				styles[`size-${size}`],
				styles[`rounded-${rounded}`],
				className,
				{
					'body-md': text === 'md',
					'body-sm': text === 'sm',
					[styles.fullWidth]: fullWidth
				}
			)}
			{...props}
		>
			{hasIcon && <SvgIcon width='20px' height='20px' Icon={Icon} />}
			{children}
		</div>
	);
};
