import { API } from '@core/config/api';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const gasPrice = {
	async get(id: number) {
		try {
			const response = await fetch(`${API.networks}${id}/get_gas_prices/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) logErrors(await response.json());

			const data = await response.json();
			return data.price;
		} catch (e) {
			logErrors(e);
		}
	}
};
