import { useEffect, useState } from 'react';
import cn from 'classnames';
import { HashLink as NavLink } from 'react-router-hash-link';

import { useAppSelector } from '@core/utils/hooks';
import { Timer } from '@components/MainComponents/Timer/Timer';

import styles from './Widgets.module.scss';

export const PresaleTimer = () => {
	// Redux hooks
	const activeRound = useAppSelector(({ app }) => app.rounds.active);

	// React hooks
	const [showTimer] = useState(false);
	const [endTimeInSeconds, setEndTimeInSeconds] = useState<{
		secondsLeft: number;
		message: string;
	} | null>(null);

	useEffect(() => {
		const secondsWhileStart =
			activeRound && (new Date(activeRound.start_date).getTime() - new Date().getTime()) / 1000;
		const secondsWhileEnd =
			activeRound && (new Date(activeRound.end_date).getTime() - new Date().getTime()) / 1000;

		if (secondsWhileStart && secondsWhileStart > 0) {
			setEndTimeInSeconds({ secondsLeft: +secondsWhileStart.toFixed(0), message: 'start' });
		} else if (secondsWhileEnd && secondsWhileEnd > 0) {
			setEndTimeInSeconds({ secondsLeft: +secondsWhileEnd.toFixed(0), message: 'end' });
		}
	}, [activeRound]);

	return (
		<div className={styles.timerWrapper}>
			<NavLink
				to='#invest'
				className={styles.buyZenfBtn}
				scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
			>
				Buy ZENF
			</NavLink>
			{showTimer && (
				<>
					<Timer sec={endTimeInSeconds?.secondsLeft} />
					<p className={cn(styles.message, 'body-md')}>
						left until Presale {activeRound?.name} {endTimeInSeconds?.message}
					</p>
				</>
			)}
		</div>
	);
};
