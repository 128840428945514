import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addAlert } from './alert.thunks';
import { IAlert } from './alert.types';

export const initialState: IAlert[] = [];

const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		setAlertAction: (state, action: PayloadAction<IAlert>) => {
			const isExists = state.some(alert => alert.text === action.payload.text);
			if (!isExists) return [...state, action.payload];
		},
		removeAlertAction: (state, action: PayloadAction<string>) => {
			return state.filter(alert => alert.id !== action.payload);
		}
	},
	extraReducers: builder => {
		builder.addCase(addAlert.fulfilled, (state, action) => {
			const isExists = state.some(alert => alert.text === action.payload.text);
			if (!isExists) return [...state, action.payload];
		});
	}
});

export const {
	actions: { setAlertAction, removeAlertAction },
	reducer: alertReducer
} = alertSlice;
