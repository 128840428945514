import React, { useState } from 'react';
import cn from 'classnames';

import { InvestorForm } from '@components/LandingComponents/Forms/InvestorForm';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { Steps } from '@sections/Investment/Widgets/Steps/Steps';

import styles from './Investment.module.scss';

export const Investment = () => {
	const [showInvestForm] = useState(false);

	return (
		<section className={styles.investment} id='invest'>
			<div className={cn('container', 'shadowedBlock', styles.container)}>
				<div className={styles.leftSide}>
					<Heading tag='h3' className='h3'>
						Be an early investor
					</Heading>
					<p className={cn(styles.description, 'subtitle')}>
						We are on a mission to build a trustless future of peer-to-peer commerce bridging web
						2.0 and web 3.0. As an early investor, you support the platform&lsquo;s growth, and
						thus, are entitled for all its shared benefits.
					</p>
					<Steps />
				</div>
				{showInvestForm && (
					<div className={styles.rightSide}>
						<InvestorForm />
					</div>
				)}
			</div>
		</section>
	);
};
