import { useState } from 'react';

import { AccordionItem } from '@sections/Faq/Widgets/AccordionItem';
import { FAQs } from '@sections/Faq/Widgets/Widgets.types';

import styles from './Widgets.module.scss';

export const Accordion = () => {
	const faqsData: FAQs = [
		{
			question: 'Which networks does your platform support?',
			answer:
				'Zenland has been integrated with 6 major EVM networks: Ethereum, Polygon, Binance Smart Chain, Avalanche, Fantom, and Gnosis. Platform users can deploy and execute escrow and marketplace contracts on any of these networks. That said, we plan to add 7 more EVM-compatible networks by Q4 2023.'
		},
		{
			question: 'What is a token cliff and how it works?',
			answer:
				'Cliff is time before vesting when no tokens are distributed to parties. During cliff ZENF tokens remain locked in the contract without any access. In cryptocurrency projects, cliff and vesting are usually used to stabilize token price and keep its supply and demand in check by limiting the selling pressure.'
		},
		{
			question: 'What is vesting and how ZENF vesting works?',
			answer:
				'Vesting is a process of locking and distributing tokens within a set period of time. ZENF token vesting is linear block-by-block, which means that a certain percentage of the total supply is unlocked progressively over a stated period of time (for example, 90K tokens purchased through presale rounds will be unlocked after 3 months and can be fully claimed in 3 months - at any time and depending on individual preferences). Both cliff and vesting schedules vary depending on the allocation category and project roadmap.'
		},
		{
			question: 'What does a deflationary model mean?',
			answer:
				'The deflationary model of token economics means that the number of tokens in circulation decreases over time due to specific mechanisms in place. Such reduction in circulating supply is aimed at increasing the value of individual tokens in case of consistent demand. A good example of the deflationary model is ETH. Ethereum Mainnet burns ETH on every transaction to reduce the number of coins in circulation.'
		},
		{
			question: 'Why US citizens are not allowed to buy ZENF?',
			answer:
				'Even though ZENF is a utility, and not a security, token, U.S. residents cannot participate in TGEs. Similar to many other ICOs, this is a necessary measure imposed due to a) the grey area between utility and security tokens in terms of regulations, and b) the U.S. ability to enforce strict policies cross-border.'
		},
		{
			question: 'What is a token bridge and is ZENF bridged?',
			answer:
				'A token bridge is a cross-chain protocol used to transfer tokens between the blockchains. This allows tokens to be used on multiple networks. For example, ZENF is an ERC-20 token that is bridged to Binance Smart Chain so that token transactions can be carried out across multiple blockchains.'
		},
		{
			question: 'Where I can buy ZENF Tokens?',
			answer:
				'You can buy ZENF tokens through various platforms. On Ethereum, Arbitrum, and Polygon networks, Uniswap is a notable option. Additionally, for the Binance Smart Chain network, ZENF tokens are available on Pancakeswap. To guarantee accuracy, make sure to copy the correct contract address from the official ZENF page.'
		}
	];

	const [clicked, setClicked] = useState<number>(-1);

	const handleToggle = (index: number) => {
		if (clicked === index) return setClicked(-1);
		setClicked(index);
	};

	return (
		<div className={styles.items}>
			{faqsData.map((data, index) => {
				return (
					<AccordionItem
						key={index}
						data={data}
						onToggle={() => handleToggle(index)}
						active={index === clicked}
					/>
				);
			})}
		</div>
	);
};
