import { ReactComponent as Coinbase } from '@assets/icons/wallets/coinbase.svg';
import { ReactComponent as Metamask } from '@assets/icons/wallets/metamask-fox.svg';
import { ReactComponent as Walletconnect } from '@assets/icons/wallets/walletconnect.svg';

export const WALLETS = [
	{ name: 'Metamask', slug: 'metamask', icon: Metamask },
	{ name: 'Coinbase', slug: 'coinbase', icon: Coinbase },
	{ name: 'Walletconnect', slug: 'walletconnect', icon: Walletconnect }
];

export const MENU_LINKS = [
	{ link: '#home', name: 'Home' },
	{ link: '#utility', name: 'Utility' },
	{ link: '#tokenomics', name: 'Tokenomics' },
	{ link: '#roadmap', name: 'Roadmap' },
	{ link: '#faq', name: 'FAQ' },
	{ link: '#invest', name: 'Invest' }
];

export const DEFAULT_AMOUNTS = [25, 50, 75, 100];

export const TWITTER_TEXT_PRESALE =
	'https://twitter.com/intent/tweet?text=I%20just%20reserved%20a%20spot%20for%20%24ZENF%20Presale%20Round%20I%0A%0AJoin%20here%20https%3A%2F%2Ftoken.zen.land%2F%0A%0A%23zenf%20%23zenland%20%23blockchainescrow';
export const TWITTER_TEXT_SALE_STARTED =
	'https://twitter.com/intent/tweet?text=I%20just%20reserved%20a%20spot%20for%20%24ZENF%20Last%20Presale%20Round%0A%0AJoin%20here%20https%3A%2F%2Ftoken.zen.land%2F%0A%0A%23zenf%20%23zenland%20%23blockchainescrow';
