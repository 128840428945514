import React from 'react';

import { useHashFragment } from '@core/utils/hooks/useHashFragment';
import { Footer, Header } from '@components/index';
import { Alert } from '@components/index';
import { ProvidersModal } from '@components/LandingComponents/ProvidersModal/ProvidersModal';
import { Sections } from '@sections/Sections';

import styles from './App.module.scss';

function App() {
	// custom Hooks
	useHashFragment();

	return (
		<div className='wrapper'>
			<Header />
			<main id='content' className='main'>
				<Sections />
			</main>
			<Footer />
			<div className={styles.bgs}>
				<div className={styles.bg}></div>
				<div className={styles.bg}></div>
				<div className={styles.bg}></div>
				<div className={styles.bg}></div>
			</div>
			<ProvidersModal />
			<Alert />
		</div>
	);
}

export default App;
