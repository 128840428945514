import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';

import styles from './Roadmap.module.scss';

export const Roadmap = () => {
	const stepsData: { title: string; list: string[]; status: 'completed' | 'in_progress' | '' }[] = [
		{
			title: 'Q3 2021',
			list: [
				'Zenland idea and brainstorming',
				'Smart contract escrow research',
				'Concept development',
				'Team buy-in discussions',
				'White-board user story mapping',
				'Resource building (bootstrapped)'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2021',
			list: [
				'MVP design and prototyping',
				'Sketching and wireframing',
				'Escrow smart contract form',
				'Contract control interface',
				'Contract chat interface',
				'UI/UX elements & brand kit'
			],
			status: 'completed'
		},
		{
			title: 'Q1 2022',
			list: [
				'Ethereum smart contract model',
				'Escrow logic implementation',
				'Dispute resolution logic',
				'User hypothesis testing',
				'Ethereum deployment',
				'Documentation v1.0'
			],
			status: 'completed'
		},
		{
			title: 'Q2 2022',
			list: [
				'MVP development',
				'Metamask wallet integration',
				'Backend integration',
				'5 EVM networks integration',
				'Official website launch',
				'Public beta release'
			],
			status: 'completed'
		},
		{
			title: 'Q3 2022',
			list: [
				'Multi-browser support',
				'Bug fixing and UI/UX optimization',
				'EVM compatibility tests',
				'Competitor analysis & research',
				'Marketing startegy',
				'Knowledge Base v.1.0'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2022',
			list: [
				'Phase I beta testing',
				'Escrow smart contract audit',
				'Zenland official release',
				'Lightpaper v.1.0',
				'Tokenomics research',
				'Mass adoption strategy'
			],
			status: 'completed'
		},
		{
			title: 'Q1 2023',
			list: [
				'TGE strategy development',
				'Whitepaper v.1.0',
				'Documentation v.2.0',
				'Phase II beta testing',
				'Token contract audit',
				'Token minting',
				'Ad/bounty campaigns'
			],
			status: 'completed'
		},
		{
			title: 'Q2 2023',
			list: [
				'Pre-approved marketplace model development',
				'User item listings with moderation system',
				'Zenland mobile-first optimization',
				'Early adopters incentive program',
				'Strategic Layer 1 & Layer 2 partnerships',
				'ZENF token Initial DEX Offering'
			],
			status: 'completed'
		},
		{
			title: 'Q3 2023',
			list: [
				'Enhanced profile security system',
				'Comprehensive reputation analytics',
				'Platform multichain compatibility framework',
				'Whitepaper v2.0 release'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2023',
			list: [
				'Complete EVM-compatibility implementation',
				'Integration with 3 additional networks',
				'Extended stablecoin/token support',
				'Technical documentation v3.0'
			],
			status: 'completed'
		},
		{
			title: 'Q1 2024',
			list: [
				'Official Zenland BitcoinTalk presence',
				'Custom Agent system development & planning',
				'Implementation of shareable profile links',
				'Advanced dispute resolution system'
			],
			status: 'completed'
		},
		{
			title: 'Q2 2024',
			list: [
				'Custom Agent system prototype launch',
				'Implementation of 50% fee share for agents',
				'Agent contract review system',
				'Public agent registration system'
			],
			status: 'completed'
		},
		{
			title: 'Q3 2024',
			list: [
				'Official Black Hat World (BHW) thread launch',
				'BitcoinTalk agent recruitment campaign',
				'Comprehensive Agent Handbook v1.0',
				'Agent reputation system UI/UX implementation'
			],
			status: 'completed'
		},
		{
			title: 'Q4 2024',
			list: [
				'BHW agent recruitment campaign',
				'AltCoinsTalk official thread launch',
				'BitcoinTalk signature campaign rollout',
				'Launch of Zenland content platform'
			],
			status: 'in_progress'
		},
		{
			title: 'Q1 2025',
			list: [
				'DAO foundation groundwork',
				'Strategic investment round preparation',
				'ZENF token marketing campaign',
				'Extended Web3 wallet integration',
				'Base network integration',
				'Optimism network integration'
			],
			status: ''
		},
		{
			title: 'Q2 2025',
			list: [
				'Zenland V2 platform launch',
				'Pure smart contract architecture',
				'DAO governance implementation',
				'Top 10 EVM chain integration',
				'Public agent rating system',
				'DAO voting mechanism'
			],
			status: ''
		}
	];

	return (
		<section className={styles.roadmap} id='roadmap'>
			<div className={cn(styles.steps, 'container', 'container-md')}>
				<div className={styles.step}>
					<Heading tag='h3' className={'h3'}>
						Roadmap
					</Heading>
					<p className={cn(styles.description, 'h6')}>
						ZENF token is a part of the Zenland project, building the only trustless smart contract
						escrow ecosystem for peer-to-peer transactions without a middleman. Roadmap milestones
						and deadlines are subject to change.
					</p>
				</div>
				{stepsData.map((data, i) => {
					return (
						<div className={styles.step} key={i}>
							<Heading
								tag='h5'
								className={cn('h5', {
									[styles.completed]: data.status === 'completed',
									[styles.inProgress]: data.status === 'in_progress'
								})}
							>
								{data.title}
							</Heading>
							<ul>
								{data.list.map((d, i) => {
									return (
										<li key={i} className='h6'>
											{d}
										</li>
									);
								})}
							</ul>
						</div>
					);
				})}
			</div>
		</section>
	);
};
