import { useState } from 'react';
import cn from 'classnames';

import { Heading } from '@components/MainComponents/Heading/Heading';
import { ChartLegends } from '@sections/Allocation/Widgets/ChartLegends';
import { DoughnutChart } from '@sections/Allocation/Widgets/DoughnutChart';
import {
	ChartLegendsDataType,
	HoveredItemIndexType
} from '@sections/Allocation/Widgets/Widgets.types';

import styles from './Allocation.module.scss';

export const Allocation = () => {
	const chartLegendsData: ChartLegendsDataType = {
		totalSupply: 200000000,
		data: [
			{
				title: '21% Marketing',
				info: '4 months cliff, linear block-by-block release in 24 months'
			},
			{
				title: '18% Development',
				info: '4 months cliff, linear block-by-block release in 24 months'
			},
			{ title: '18% Presale', info: '3 months cliff, linear block-by-block release in 3 months' },
			{ title: '15% Team', info: '12 months cliff, linear block-by-block release in 24 months' },
			{
				title: '10% Community',
				info: '10% (1% of total supply) at TGE, linear block-by-block release in 12 months'
			},
			{ title: '8% IDO', info: 'Fully unlocked at IDO' },
			{
				title: '5% Early adopters',
				info: 'Zenland user reward funds; used as per program needs'
			},
			{
				title: '5% Treasury',
				info: 'Reserve funds for building Zenland DAO and unforeseen needs'
			}
		]
	};

	const [hoveredItemIndex, setHoveredItemIndex] = useState<HoveredItemIndexType>(-1);
	const [hoveredChartLegend, setHoveredChartLegend] = useState(-1);

	return (
		<section className={styles.allocation} id='tokenomics'>
			<div className={cn('container', styles.container)}>
				<div className={styles.leftSide}>
					<Heading tag='h3' className={cn('containerTitle', 'h3', styles.containerTitle)}>
						Token allocation
					</Heading>
					<div className={styles.doughnutChart}>
						<DoughnutChart setItemIndex={setHoveredItemIndex} hoveredLegend={hoveredChartLegend} />
					</div>
				</div>
				<div className={styles.rightSide}>
					<ChartLegends
						chartData={chartLegendsData}
						activeChartDataIndex={hoveredItemIndex}
						setHoveredLegend={setHoveredChartLegend}
					/>
				</div>
			</div>
		</section>
	);
};
