import React from 'react';
import cn from 'classnames';

import { WALLETS } from '@core/config/constants';
import { ProviderEnum } from '@core/models/enums/provider.enum';
import { setModal } from '@core/store/app/app.slice';
import { setProviderThunk } from '@core/store/app/app.thunks';
import { addProviderLoading } from '@core/store/me/me.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { Modal } from '@components/MainComponents/Modal/Modal';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { ReactComponent as Cross } from '@assets/icons/cross.svg';

import styles from './ProvidersModal.module.scss';

export const ProvidersModal = () => {
	const [isOpen, networks, chainId] = useAppSelector(({ app, me }) => [
		app.modal.isOpen,
		app.networks,
		me.chainId
	]);
	const dispatch = useAppDispatch();

	const handleConnect = async (providerName: ProviderEnum) => {
		if (networks) {
			const chainNumbers = networks.map(n => parseInt(n.chain_id));
			const defaultChainId = networks[0].chain_id;

			if (providerName === ProviderEnum.Walletconnect && localStorage.length > 0) {
				const recentDataExist = Object.keys(localStorage).findIndex(l => {
					return l === 'W3M_RECENT_WALLET_DATA' || l === 'WALLETCONNECT_DEEPLINK_CHOICE';
				});
				if (recentDataExist > -1) localStorage.clear();
			}

			if (chainNumbers && (chainId || defaultChainId)) {
				dispatch(
					setProviderThunk({
						providerName,
						chainId: parseInt(chainId || defaultChainId),
						chainNumbers
					})
				);
			}

			dispatch(setModal(false));
		}
	};

	const handleCloseModal = () => {
		dispatch(setModal(false));
		dispatch(addProviderLoading(false));
	};

	return (
		<Modal isActive={isOpen} className={styles.modal}>
			<>
				<Heading tag='h4' className='subtitle-md'>
					Select Wallet
				</Heading>
				<Button onClick={handleCloseModal} className={cn(styles.closeModalBtn, styles.btn)}>
					<SvgIcon Icon={Cross} />
				</Button>
				<div className={styles.providers}>
					{WALLETS.map((wallet, i) => {
						return (
							<Button
								className={cn(styles.providerBtn, styles.btn)}
								key={i}
								onClick={async () => {
									await handleConnect(wallet.slug as ProviderEnum);
								}}
							>
								<SvgIcon Icon={wallet.icon} />
								<p className='body'>{wallet.name}</p>
							</Button>
						);
					})}
				</div>
			</>
		</Modal>
	);
};
