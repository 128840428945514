import * as process from 'process';

import { ProviderEnum } from '@core/models/enums/provider.enum';
import { addAlert } from '@core/store/alert/alert.thunks';
import { store } from '@core/store/store';
import { logErrors } from '@core/utils/logErrors';
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from '@coinbase/wallet-sdk';
import MetaMaskSDK from '@metamask/sdk';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { IEthereumProvider } from '@walletconnect/ethereum-provider/dist/types/types';

export const Providers = {
	async Coinbase({
		chainNumber
	}: {
		chainNumber: number;
	}): Promise<CoinbaseWalletProvider | undefined> {
		try {
			const coinbaseWallet = new CoinbaseWalletSDK({
				appName: 'ZENF token',
				darkMode: false
			});

			return coinbaseWallet.makeWeb3Provider(
				`https://mainnet.infura.io/v3/${process.env.REACT_APP_JSON_RPC_ID}`,
				chainNumber
			);
		} catch (e) {
			logErrors(e);
		}
	},
	async Walletconnect({
		chainNumbers
	}: {
		chainNumbers: number[] | undefined;
	}): Promise<IEthereumProvider | undefined> {
		try {
			return await EthereumProvider.init({
				projectId: process.env.REACT_APP_WAGMI_CLIENT_PROJECT_ID || '',
				chains: [1],
				optionalChains: chainNumbers,
				showQrModal: true,
				methods: [
					'eth_sendTransaction',
					'eth_requestAccounts',
					'wallet_switchEthereumChain',
					'wallet_addEthereumChain'
				]
			});
		} catch (e) {
			logErrors(e);
		}
	},
	async Metamask() {
		try {
			const MMSDK = new MetaMaskSDK({
				injectProvider: false
			});
			const provider = MMSDK.getProvider();

			if (
				(provider && 'isTrust' in provider && provider.isTrust) ||
				(provider && 'isSafePal' in provider && provider.isSafePal)
			) {
				store.dispatch(
					addAlert({
						text: 'Please disable TrustWallet extension to use Metamask extension',
						type: 'info',
						isSubmit: true
					})
				);
				return;
			}
			return provider;
		} catch (e) {
			logErrors(e);
		}
	}
};

export const getSelectedProvider = async (
	providerName: string,
	chainNumber: number,
	chainNumbers: number[]
) => {
	switch (providerName) {
		case ProviderEnum.Metamask: {
			return await Providers.Metamask();
		}
		case ProviderEnum.Coinbase: {
			return await Providers.Coinbase({ chainNumber });
		}
		case ProviderEnum.Walletconnect: {
			return await Providers.Walletconnect({ chainNumbers });
		}
	}
};

export const enableSelectedProvider = async (providerName: string) => {
	const { myProvider } = window;

	try {
		if (myProvider) {
			if (providerName === ProviderEnum.Walletconnect) {
				const [account] = await myProvider.enable();
				return account;
			}
			const [account] = await myProvider.request({ method: 'eth_requestAccounts', params: [] });
			return account;
		}
	} catch (e) {
		logErrors(e);
	}
};

export const getProviderChainId = async () => {
	const { myProvider } = window;
	try {
		return await myProvider.request({ method: 'eth_chainId' });
	} catch (e) {
		logErrors(e);
	}
};
