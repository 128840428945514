import React from 'react';

import { HeadingProps } from './HeadingProps';

export const Heading = ({ tag, className, children, ...props }: HeadingProps) => {
	const Tag = tag;
	return (
		<Tag className={className} {...props}>
			{children}
		</Tag>
	);
};
