import { Validations } from '@core/utils/hooks/useForm';
import { emailPattern } from '@core/utils/regex';
import { IInvestmentForm } from '@sections/Investment/Investment.type';

export const InvestorFormValidations: (
	minValue: number,
	maxValue: number,
	activeRoundNotStarted: boolean
) => Validations<Record<keyof IInvestmentForm, IInvestmentForm>> = (
	minValue,
	maxValue,
	activeRoundNotStarted
) => {
	return {
		amount: {
			custom: {
				isValid: value => !!(value && +value >= minValue && +value <= maxValue),
				message: ` Minimum: ${minValue}$, Maximum: ${maxValue}$`
			}
		},
		email: activeRoundNotStarted
			? {
					custom: {
						isValid: value => !!(value && String(value).toLowerCase().match(emailPattern)),
						message: 'Enter a valid email'
					}
			  }
			: {}
	};
};
