import { createAsyncThunk } from '@reduxjs/toolkit';

import { Vestings } from '@core/services/vestings';
import {
	setIsDisabled,
	setIsLoading,
	setRequestedRound,
	setVesting
} from '@core/store/vesting/vesting.slice';
import { VestingTypes } from '@core/store/vesting/vesting.types';
import { logErrors } from '@core/utils/logErrors';

export const getVestingDetails = createAsyncThunk<boolean, VestingTypes>(
	'vesting/getDetails',
	async ({ sellRound, address }, { dispatch }) => {
		dispatch(setIsDisabled(true));
		dispatch(setIsLoading(true));

		try {
			const res = await Vestings.get({ sellRound, address });

			if (res && typeof res !== 'string' && !Array.isArray(res)) {
				dispatch(setVesting(res));
				dispatch(setRequestedRound(sellRound));
				dispatch(setIsDisabled(true));
				return true;
			} else logErrors(res);
			dispatch(setIsDisabled(false));
			return false;
		} catch (e) {
			logErrors(e);
			dispatch(setIsDisabled(false));
			return false;
		} finally {
			dispatch(setIsLoading(false));
		}
	}
);
