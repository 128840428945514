import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INetwork } from '@core/models/interfaces/networkInterfaces';
import { RoundInterfaces } from '@core/models/interfaces/roundInterfaces';
import { IToken } from '@core/models/interfaces/tokenInterfaces';
import { IInitialState } from '@core/store/app/app.types';

export const initialState: IInitialState = {
	networks: null,
	tokens: null,
	rounds: { data: null, active: null, activeRoundNotStarted: true },
	modal: {
		isOpen: false
	}
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setNetworks: (state, action: PayloadAction<INetwork[] | null>) => {
			state.networks = action.payload;
		},
		setTokens: (state, action: PayloadAction<IToken[] | null>) => {
			state.tokens = action.payload;
		},
		setRounds: (state, action: PayloadAction<RoundInterfaces[] | null>) => {
			state.rounds.data = action.payload;
		},
		setActiveRound: (state, action: PayloadAction<RoundInterfaces | null>) => {
			state.rounds.active = action.payload;
		},
		setActiveRoundNotStarted: (state, action: PayloadAction<boolean>) => {
			state.rounds.activeRoundNotStarted = action.payload;
		},
		setModal: (state, action: PayloadAction<boolean>) => {
			state.modal.isOpen = action.payload;
		}
	}
});

export const {
	reducer: appReducer,
	actions: { setNetworks, setTokens, setRounds, setActiveRound, setActiveRoundNotStarted, setModal }
} = appSlice;
