import { createAsyncThunk } from '@reduxjs/toolkit';

import { removeAlertAction } from './alert.slice';
import { IAlert } from './alert.types';

export const addAlert = createAsyncThunk<IAlert, Omit<IAlert, 'id'>>(
	'alert/createAlert',
	async (payload, thunkAPI) => {
		const id = Date.now().toString();

		if (!payload.isSubmit) {
			let timeout = 2000;
			const wordsCount = payload.text.split(' ').length;

			if (wordsCount > 3) {
				const x = Math.ceil(wordsCount / 3); // reading 3 words per second
				timeout = x * 1000; // convert to milliseconds
			}

			setTimeout(() => {
				thunkAPI.dispatch(removeAlertAction(id));
			}, timeout);
		}
		return { ...payload, id };
	}
);
