import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { timeConvert } from '@core/utils/hooks';

import { TimerProps } from './Timer.props';

import styles from './Timer.module.scss';

export const Timer = ({
	sec,
	isExpired = false,
	variant = 'large',
	className,
	...props
}: TimerProps) => {
	/* React hooks */
	const [secs, setSecs] = useState(0);
	useEffect(() => {
		const myInterval = setInterval(() => {
			if (secs > 0) {
				if (isExpired) setSecs(secs + 1);
				else setSecs(secs - 1);
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});
	useEffect(() => {
		if (typeof sec === 'number') setSecs(sec);
		else setSecs(-1);
	}, [sec]);

	/* Custom hooks */
	const { days, hours, minutes, seconds } = timeConvert(secs);

	const addZero = (num: number) => {
		if (num.toString().length === 1) return `0${num}`;
		return num;
	};

	return (
		<div
			className={cn(className, {
				[styles.timer]: variant === 'large',
				[styles.timerMinimal]: variant === 'minimal'
			})}
			{...props}
		>
			<p className={styles.days}>{addZero(days)}</p>
			<span className={styles.colon}>:</span>
			<p className={styles.hours}>{addZero(hours)}</p>
			<span className={styles.colon}>:</span>
			<p className={styles.minutes}>{addZero(minutes)}</p>
			<span className={styles.colon}>:</span>
			<p className={styles.seconds}>{seconds ? addZero(seconds) : addZero(0)}</p>
		</div>
	);
};
