import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from '@core/store/store';
import PiwicPro from '@piwikpro/react-piwik-pro';

import App from './App';

import './styles/index.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);
PiwicPro.initialize('14d1fc69-395e-45b1-849b-285791bf74ee', 'https://zenland.containers.piwik.pro');

root.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<App />
			</Provider>
		</Router>
	</React.StrictMode>
);
