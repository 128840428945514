import { useEffect } from 'react';

export function useHashFragment(offset = 150, trigger = true) {
	useEffect(() => {
		const scrollToHashElement = () => {
			const { hash } = window.location;
			const elementToScroll = document.getElementById(hash?.substring(1));

			if (!elementToScroll) return;
			const paddingTop = parseInt(window.getComputedStyle(elementToScroll, null).paddingTop);

			window.scrollTo({
				top: elementToScroll.offsetTop + paddingTop + offset,
				behavior: 'smooth'
			});
		};

		if (!trigger) return;

		scrollToHashElement();
		// window.addEventListener('hashchange', scrollToHashElement);
		// return () => {
		// 	window.removeEventListener('hashchange', scrollToHashElement);
		// };
	}, [trigger]);
}
