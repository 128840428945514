import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import {
	DEFAULT_AMOUNTS,
	TWITTER_TEXT_PRESALE,
	TWITTER_TEXT_SALE_STARTED
} from '@core/config/constants';
import { INetwork } from '@core/models/interfaces/networkInterfaces';
import { IToken } from '@core/models/interfaces/tokenInterfaces';
import { Tokens } from '@core/services/tokens';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setModal } from '@core/store/app/app.slice';
import { buyZenfThunk } from '@core/store/app/app.thunks';
import { addChainId } from '@core/store/me/me.slice';
import { logoutThunk, setProviderChainId } from '@core/store/me/me.thunks';
import { store } from '@core/store/store';
import { setIsConfirmingTransaction } from '@core/store/transaction/transaction.slice';
import { getTransactionThunk } from '@core/store/transaction/transaction.thunks';
import { cropAddress, useForm } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Badge, Button, Input, Select, SvgIcon } from '@components/index';
import { ReactComponent as TwitterIcon } from '@assets/icons/social/Twitter.svg';
import { ReactComponent as ZenToken } from '@assets/icons/tokens/zen_token.svg';
import { IInvestmentForm } from '@sections/Investment/Investment.type';

import { waitlistHandler } from './handlers/waitlistHandler';
import { InvestorFormValidations } from './validations';

import styles from './Forms.module.scss';
export const InvestorForm = () => {
	// Redux hooks
	const dispatch = useAppDispatch();
	const [networks, tokens, rounds, me, transaction, isConfirming, vesting] = useAppSelector(
		({ app, me, transaction, vesting }) => [
			app.networks,
			app.tokens,
			app.rounds,
			me,
			transaction.data,
			transaction.isConfirmingTransaction,
			vesting
		]
	);

	// React hooks
	const [network, setNetwork] = useState<{ name: string; value: number }>();
	const [networkOptions, setNetworkOptions] = useState<{ name: string; value: number }[]>();
	const [networkPlaceholder, setNetworkPlaceholder] = useState('Network');
	const [isNetworkChanging, setIsNetworkChanging] = useState(false);

	const [token, setToken] = useState<{ name: string; value: number }>();
	const [tokenData, setTokenData] = useState<IToken>();
	const [tokenOptions, setTokenOptions] = useState<{ name: string; value: number }[]>();

	const [tokenValue, setTokenValue] = useState(0);

	const [checkboxesState, setCheckboxesState] = useState({
		isTerms: false,
		isNotUsCitizen: false,
		isAgreedRisk: false
	});

	const [validationData, setValidationData] = useState({ min_value: 0, max_value: 100 });

	const [isBtnDisabled, setIsBtnDisabled] = useState(true);
	const [isBtnLoading, setIsBtnLoading] = useState(false);

	const [formSubmitResponse, setFormSubmitResponse] = useState<{
		text: string;
		status: 'danger' | 'success' | 'warn';
	} | null>(null);

	// Form hook
	const { handleSubmit, handleChange, data, setData, errors } = useForm<IInvestmentForm>({
		initialValues: {
			email: '',
			amount: ''
		},
		onSubmit: async () => {
			setIsBtnDisabled(true);
			setIsBtnLoading(true);

			if (rounds.activeRoundNotStarted) {
				const result = await waitlistHandler(data);
				if (result) {
					setTimeout(() => {
						setIsBtnDisabled(result.isButtonDisabled);
					}, 1000);
					setFormSubmitResponse({
						text: result.response.text,
						status: result.response.status
					});
				}
			} else {
				if (me.address && tokens && tokenData) {
					const { network, amount, sell_round, token, email } = data;
					dispatch(
						buyZenfThunk({
							email,
							token,
							amount,
							address: me.address,
							network,
							sell_round,
							tokenData
						})
					);
				}
			}
			setIsBtnLoading(false);
		},
		validations: InvestorFormValidations(
			validationData.min_value,
			validationData.max_value,
			rounds.activeRoundNotStarted
		)
	});

	// React hooks
	useEffect(() => {
		let _getTransaction: NodeJS.Timeout | undefined;
		if (transaction) {
			if (transaction.status === 'pending') {
				_getTransaction = setInterval(() => dispatch(getTransactionThunk(transaction.id)), 3000);
			} else {
				_getTransaction && clearInterval(_getTransaction);
				store.dispatch(setIsConfirmingTransaction(false));
			}
		}
		return () => {
			if (_getTransaction) clearInterval(_getTransaction);
		};
	}, [transaction]);

	useEffect(() => {
		const activeRoundId = rounds.active && rounds.active.id;
		if (activeRoundId) setData(prevState => ({ ...prevState, sell_round: activeRoundId }));
		if (rounds.active) {
			setValidationData({ min_value: rounds.active.min_value, max_value: rounds.active.max_value });
		}
	}, [rounds.active]);

	useEffect(() => {
		if (!rounds.activeRoundNotStarted && networks) {
			const foundNetwork = networks[0];
			if (foundNetwork) {
				dispatch(addChainId(foundNetwork.chain_id));
				setNetwork({ value: foundNetwork.id, name: foundNetwork.display_name });
				setData(prevState => ({ ...prevState, network: foundNetwork.id }));
			}
		}
	}, [rounds.activeRoundNotStarted, networks]);

	useEffect(() => {
		if (networks) {
			const networkValues = networks.map(n => ({
				name: n.display_name,
				value: n.id
			}));
			setNetworkOptions(networkValues);
			setNetwork(networkValues[0]);
		}
	}, [networks]);

	useEffect(() => {
		if (me.provider.name && me.chainId && me.address) {
			const existNetwork = networks?.find(n => n.chain_id === me.chainId);
			if (existNetwork) {
				setNetwork({ value: existNetwork.id, name: existNetwork.display_name });
				setData(prevState => ({ ...prevState, network: existNetwork.id }));
				networkPlaceholder && setNetworkPlaceholder('');
			} else
				dispatch(
					addAlert({
						text: 'Please change default network from our list',
						type: 'error',
						isSubmit: true
					})
				);
		}
	}, [me.provider.name]);

	useEffect(() => {
		const { network, amount, sell_round, isTerms, isAgreedRisk, isNotUsCitizen, token, email } =
			data;
		if (network && amount && isTerms && isNotUsCitizen && isAgreedRisk && sell_round && token) {
			if (rounds.activeRoundNotStarted) {
				email && setIsBtnDisabled(false);
			} else setIsBtnDisabled(false);
		} else setIsBtnDisabled(true);
	}, [data]);

	useEffect(() => {
		if (rounds.active) {
			const tokenPrice = 1 / +rounds.active.price_per_token;
			setTokenValue(tokenPrice * +data.amount);
		}
	}, [data.amount]);

	useEffect(() => {
		(async () => {
			if (data.network && networks) {
				const selectedTokens: IToken[] = await Tokens.getOne(data.network);
				const tokenValues = selectedTokens.map(n => ({
					name: n.name,
					value: n.id
				}));
				setTokenOptions(tokenValues);
				setToken(tokenValues[0]);

				setData(prevState => ({ ...prevState, token: tokenValues[0].value }));
			}
		})();
	}, [data.network]);

	useEffect(() => {
		if (data.token && tokens) {
			const foundTokenData = tokens.find(token => token.id === data.token);
			if (foundTokenData) setTokenData(foundTokenData);
		}
	}, [data.token]);

	useEffect(() => {
		if (transaction && transaction.status === 'confirmed') {
			setFormSubmitResponse({
				status: 'success',
				text: `The amount has been reserved! Please check back your address in the claim form below after July 15. If you have any questions contact us at <a href='https://t.me/zenland_support' target='_blank' rel='noopener noreferrer'>Telegram</a> or email to <a href='mailto:diornov@zen.land'>diornov@zen.land</a>`
			});
		}
	}, [transaction]);

	const connectHandler = () => {
		if (!me.address) dispatch(setModal(true));
	};

	const logoutHandler = async () => {
		if ('disconnect' in window.myProvider) {
			await window.myProvider.disconnect();
		}
		dispatch(logoutThunk());
	};

	const agreementHandlers =
		(field: 'terms' | 'notUsCitizen' | 'agreedRisk') => (state: boolean) => {
			switch (field) {
				case 'terms': {
					setData(prevState => ({ ...prevState, isTerms: state }));
					setCheckboxesState(prevState => ({ ...prevState, isTerms: state }));
					break;
				}
				case 'notUsCitizen': {
					setData(prevState => ({ ...prevState, isNotUsCitizen: state }));
					setCheckboxesState(prevState => ({ ...prevState, isNotUsCitizen: state }));
					break;
				}
				case 'agreedRisk': {
					setData(prevState => ({ ...prevState, isAgreedRisk: state }));
					setCheckboxesState(prevState => ({ ...prevState, isAgreedRisk: state }));
				}
			}
		};

	const selectHandlers = (field: 'network' | 'token') => (name: string, value: number) => {
		switch (field) {
			case 'network': {
				(async () => {
					if (networks) {
						if (!rounds.activeRoundNotStarted && me.provider.name) {
							const selectedNetworkData: INetwork | undefined = networks.find(n => n.id === value);
							if (selectedNetworkData) {
								const { chain_id, metamask_name, rpc, decimals, symbol, explorer_url } =
									selectedNetworkData;
								setIsNetworkChanging(true);
								const web3NetworkChange = await dispatch(
									setProviderChainId({
										chainName: metamask_name,
										chainId: chain_id,
										decimals,
										rpcUrls: [rpc],
										symbol,
										blockExplorerUrls: [explorer_url]
									})
								);
								if (web3NetworkChange) {
									setData(prevState => ({ ...prevState, network: value }));
									setNetwork({ name, value });
								}
								setIsNetworkChanging(false);
							}
						} else {
							setData(prevState => ({ ...prevState, network: value }));
							setNetwork({ name, value });
						}
						setNetworkPlaceholder('');
					}
				})();
				break;
			}
			case 'token': {
				setData(prevState => ({ ...prevState, token: value }));
				setToken({ name, value });
			}
		}
	};
	const defaultAmountHandler = (value: number) => {
		if (rounds.active) {
			const max = rounds.active.max_value;
			setData(prevState => ({
				...prevState,
				amount: (value * max * 0.01).toString()
			}));
		}
	};

	useEffect(() => {
		if (me.address && me.chainId && networks && vesting.data) {
			const networkData = networks.find(n => n.chain_id === me.chainId);
			if (networkData && networkData.id !== network?.value) {
				const { id, display_name } = networkData;

				setData(prevState => ({ ...prevState, network: networkData.id }));
				setNetwork({ name: display_name, value: id });
			}
		}
	}, [me.chainId]);

	return (
		<>
			<form
				className={styles.form}
				onSubmit={async e => {
					setFormSubmitResponse(null);
					await handleSubmit(e);
				}}
			>
				<p className={cn('body-sm', styles.questions)}>
					Important Announcement: Read Before Investing{' '}
					<a
						target='_blank'
						rel='nofollow noreferrer'
						href='https://twitter.com/khasanov_dior/status/1668945343566827521'
					>
						https://twitter.com/khasanov_dior/status/1668945343566827521
					</a>
				</p>
				<p className={cn('body-sm', styles.questions)}>
					Need help or have questions? Reach out to our support team on Telegram at{' '}
					<a href='https://t.me/zenland_support'>@zenland_support</a>. We&lsquo;re here to assist
					you!
				</p>
				<label className={styles.formInputLabel}>
					<p className={cn('body-md', styles.formInputText)}>
						E-mail{' '}
						{rounds.activeRoundNotStarted || (
							<span className={styles.additionalText}>(optional)</span>
						)}
					</p>
					<Input
						type='email'
						className={cn(styles.formInput, { [styles.error]: errors.email })}
						placeholder='example@example.com'
						value={data.email}
						onChange={handleChange('email')}
						required={rounds.activeRoundNotStarted}
					/>
					{errors.email && <p className={styles.errorText}>{errors.email}</p>}
				</label>
				<label className={cn(styles.formInputLabel, styles.formInputLabelNetwork)}>
					<p className={cn('body-md', styles.formInputText)}>Choose network</p>
					{network && networkOptions && (
						<Select<number>
							className={cn(styles.formInput)}
							defaultOption={network}
							options={networkOptions}
							setOption={e => selectHandlers('network')(e.name, e.value)}
							size='xl'
						/>
					)}
				</label>
				{token && tokenOptions && !networkPlaceholder && (
					<label className={cn(styles.formInputLabel, styles.formInputLabelCoin)}>
						<p className={cn('body-md', styles.formInputText)}>Choose Stablecoin</p>
						<Select
							className={cn(styles.formInput)}
							defaultOption={token}
							options={tokenOptions}
							setOption={e => selectHandlers('token')(e.name, e.value)}
							size='xl'
						/>
					</label>
				)}
				{isNetworkChanging && (
					<Badge color='warn' hasIcon fullWidth>
						Please check your wallet to change Network
					</Badge>
				)}
				<div className={styles.formInputLabelWrapper}>
					<label className={styles.formInputLabel} htmlFor='amount'>
						<div className={styles.formInputTextWrapper}>
							<p className={cn('body-md', styles.formInputText)}>Amount</p>
							<p className={cn('body-md', styles.formInputText)}>You will receive</p>
						</div>
					</label>
					<div className={cn(styles.formInputContainer, { [styles.error]: errors.amount })}>
						<div className={styles.formInputWrapper}>
							<Input
								id='amount'
								className={styles.formInput}
								placeholder={'$' + validationData.min_value + ' - ' + validationData.max_value}
								autoComplete='off'
								inputMode='numeric'
								value={data.amount}
								onChange={handleChange('amount')}
							/>
							<span className={cn(styles.amountToken, 'body')}>
								<SvgIcon Icon={ZenToken} />
								<p>{tokenValue.toFixed(2)} ZENF</p>
							</span>
						</div>
						<div className={styles.defaultAmounts}>
							{DEFAULT_AMOUNTS.map((amount, index) => {
								return (
									<Button
										className={styles.defaultAmount}
										key={index}
										onClick={() => defaultAmountHandler(amount)}
										type='button'
									>
										{amount}%
									</Button>
								);
							})}
						</div>
					</div>
					{errors.amount && <p className={styles.errorText}>{errors.amount}</p>}
				</div>
				{rounds.activeRoundNotStarted || (
					<div className={cn(styles.formInputLabelWrapper, styles.formInputAddress)}>
						<div className={styles.formInputBtns}>
							<Button
								className={styles.connectWallet}
								type='button'
								color='primary'
								variant='contained'
								size='medium'
								onClick={connectHandler}
								isLoader={me.provider.isLoading}
							>
								{me.address ? cropAddress(me.address) : 'Connect wallet'}
							</Button>
							{me.address && (
								<Button
									className={styles.logoutWallet}
									type='button'
									color='primary'
									variant='contained'
									size='medium'
									onClick={logoutHandler}
								>
									Log out
								</Button>
							)}
						</div>
					</div>
				)}
				<div className={styles.formInputCheckboxes}>
					<label className={cn(styles.formInputLabel, styles.formInputCheckbox)}>
						<Input
							type='checkbox'
							className={styles.formInput}
							checked={checkboxesState.isTerms}
							onChange={e => agreementHandlers('terms')(e.target.checked)}
						/>
						<p className={cn('body-sm', styles.formInputText)}>
							I certify that I am of the legal age in my jurisdiction, and I have read{' '}
							<a
								href='https://zen.land/terms-of-service/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Terms of Service
							</a>{' '}
							and{' '}
							<a href='https://zen.land/privacy-policy/' target='_blank' rel='noopener noreferrer'>
								Privacy Policy.
							</a>
						</p>
					</label>
					<label className={cn(styles.formInputLabel, styles.formInputCheckbox)}>
						<Input
							type='checkbox'
							className={styles.formInput}
							checked={checkboxesState.isNotUsCitizen}
							onChange={e => agreementHandlers('notUsCitizen')(e.target.checked)}
						/>
						<p className={cn('body-sm', styles.formInputText)}>
							I am not a U.S. citizen or a company that is located, incorporated, or has a
							registered agent in the U.S.
						</p>
					</label>
					<label className={cn(styles.formInputLabel, styles.formInputCheckbox)}>
						<Input
							type='checkbox'
							className={styles.formInput}
							checked={checkboxesState.isAgreedRisk}
							onChange={e => agreementHandlers('agreedRisk')(e.target.checked)}
						/>
						<p className={cn('body-sm', styles.formInputText)}>
							I have read{' '}
							<a href='https://zen.land/disclaimer' target='_blank' rel='noopener noreferrer'>
								Risk Disclosure
							</a>{' '}
							and I am aware of 3 months cliff and 3 months vesting, and DYOR.
						</p>
					</label>
					<p className={cn(styles.formDescription, 'body-sm')}>
						By using this website and participating in the presale you agree to our{' '}
						<a href='https://zen.land/terms-of-service/' target='_blank' rel='noopener noreferrer'>
							Terms of Service
						</a>
						. Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies
						may go up or down. Profits may be subject to capital gains or other taxes applicable in
						your jurisdiction.
					</p>
				</div>
				{rounds.activeRoundNotStarted ? (
					<>
						<Button
							variant='contained'
							size='large'
							color='primary'
							className={styles.submitBtn}
							disabled={isBtnDisabled}
							isLoader={isBtnLoading}
						>
							Add me to waitlist
						</Button>
						<Button
							variant='outlined'
							size='large'
							as='externalLink'
							className={cn('twitter-share-button', styles.twitterLink, {
								[styles.disabled]: !(formSubmitResponse && formSubmitResponse?.status === 'success')
							})}
							href={TWITTER_TEXT_PRESALE}
							data-size='large'
						>
							Share
							<SvgIcon Icon={TwitterIcon} />
						</Button>
					</>
				) : (
					me.address && (
						<>
							<Button
								variant='contained'
								size='large'
								color='primary'
								className={styles.submitBtn}
								disabled={isBtnDisabled}
								isLoader={isBtnLoading}
								isConfirming={isConfirming}
							>
								Buy ZENF
							</Button>
							<Button
								variant='outlined'
								size='large'
								as='externalLink'
								className={cn('twitter-share-button', styles.twitterLink, {
									[styles.disabled]: !(transaction && transaction.status === 'confirmed')
								})}
								href={TWITTER_TEXT_SALE_STARTED}
								data-size='large'
							>
								Share
								<SvgIcon Icon={TwitterIcon} />
							</Button>
						</>
					)
				)}
			</form>
			{formSubmitResponse && (
				<Badge
					size='md'
					hasIcon
					color={formSubmitResponse.status}
					className={cn(styles.submitMessage, 'body-sm')}
				>
					<p>
						{Array.isArray(formSubmitResponse.text)
							? parse(formSubmitResponse.text[0])
							: parse(formSubmitResponse.text)}
					</p>
				</Badge>
			)}
		</>
	);
};
